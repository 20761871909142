.reportContainer {
	height: 100%;
	display: flex;
	border-radius: 5px;
	background-color: rgba(244, 244, 244, 0.95);
	box-shadow: 2px 2px 3px 0px rgba(79, 79, 79, 0.2);
	overflow: hidden;
}

.menuContainer {
	display: flex;
	flex-direction: column;
	justify-content: left;
	background-color: rgba(56, 68, 87, 0.05);
	width: 20rem;
	text-align: left;
	font-size: 20px;
	font-weight: 600;
}

.contentContainer {
	height: 100%;
	width: 100%;
	padding: 1.4rem;
}

.menuLink {
	color: #384457;
	opacity: 0.5;
	text-decoration: none;
	border-left: 0.5rem solid transparent;
	padding: 1rem;
	padding-left: 1.5rem;
}

.selectedLink {
	color: #384457;
	opacity: 1.0;
	text-decoration: none;
	border-left: 0.5rem solid #384457;
	padding: 1rem;
	padding-left: 1.5rem;
}