
// --- Brand Colors ---
// Primary Colors
$westock-red: #c33;
$westock-orange: #f8991d;

// Secondary Colors
$westock-yellow: #ffcd05;
$westock-red-dark: #7c1714;  // burgundy

//
$westock-cream: #fcf4e4;
$westock-rose: #faeced;

$westock-white: #fff;

// Existing Dashboard blue:
//  muted
//117, 147, 195
// #7593C3
//
// bright
// rgb(41, 121, 255)
// #2979FF


// --- Generated Brand Colors ---
$westock-blue: #2a61a0;


// --- Generated Brand Colors To Test ---
$westock-green-light: #29bea8;
$westock-green-light2: #3c9287;
$westock-green: #104021;
$westock-green2: #5f8e1f;
$westock-green-dark: #113b1e;
$westock-blue-light: #1db6c7; // aqua?
$westock-blue-light2: #2cb0c4; // aqua2?
$westock-blue2: #139db1;
$westock-blue3: #0e8ab4;
$westock-blue-dark: #102053;
$westock-blue-dark2: #07274d;
$westock-blue-darkest: #09182c;

// --- Set Standard Color Names ---
$white: $westock-white;
$blue: $westock-blue;
