@import "bootstrap/scss/mixins/image";
@import "styles/main";

.public_container__header {
	text-align:center;
	margin-bottom: map-get($spacers, 4);

}

.logo {
	padding: 1.5rem;
	max-width: 300px;
	@include img-fluid;
}
