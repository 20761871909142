.OverviewContainer {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
	gap: 1.2rem;
}

.OverviewContainerInternal {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
	gap: 1.2rem;
	background-color: white;
	padding: 20px;
}

.guideContainer {
	position: relative;
	display: flex;
	background-color: #f4f4f4;
	color: #384457;
	border-radius: 5px;
	box-shadow: 2px 2px 3px 0px rgba(79, 79, 79, 0.2);
	padding: 30px;
}

.instructionsContainer {
	padding-right: 60px;
	width: 60%;
	z-index: 2;
}

.textContainer {
	display: inline-block;
}

.title {
	color: #384457;
	font-size: 30px;
	font-weight: bold;
	margin-bottom: 20px;
}

.subTitle {
	color: #384457;
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 20px;
}

.helperText {
	font-size: 14px;
	color: rgb(129, 129, 129);
	margin-top: 5px;
	margin-bottom: 40px;
}

.guideFooter{
	color: #384457;
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 40px;
}

.guideSection {
	color: #384457;
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.guideFinalSection {
	color: #384457;
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 40px;
	display: flex;
	align-items: center;
	opacity: 0;
	justify-content: space-between;
	animation: fadein 0.8s ease-out forwards;
	animation-delay: 1.0s;
}

.guideLink {
	color: white;
	background-color: #7593C3;
	border-radius: 5px;
	text-decoration: none;
	padding: 10px;
	text-align: center;
	min-width: 170px;
	float: right;
	margin-left: 40px;
	font-size: 18px;
	font-weight: 600;
}

.videoContainer {
	width: 40%;
	margin-right: 0;
	z-index: 2;
}

.video {
	height: 100%;
	width: 100%;
	float: right;
}

.checkLoader {
	display: inline-block;
	position: relative;
	min-height: 40px;
	min-width: 40px;
	margin-right: 20px;
	border-radius: 50%;
	border: 2px solid #888888;
	border-right-color: #75C393;
	animation: loaderSpin 1.0s infinite linear;
}

.completeLoad {
	animation: none;
	border-color: #75C393;
	transition: border 500ms ease-out;
}

.checkMark {
	display: none;
	position: absolute;
	top: 18px;
	left: 9px;
	height: 20px;
	width: 10px;
	border-right: #75C393 solid 2px;
	border-top: #75C393 solid 2px;
	transform-origin: left top;
	transform: scaleX(-1) rotate(135deg);
}

.drawCheck {
	display: block;
	transform-origin: left top;
	opacity: 0;
	animation: checkmark 0.8s ease-out forwards;
	animation-delay: 1.0s;
}

.checkNText {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.finalContainer {
	color: #384457;
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes loaderSpin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes checkmark {
	0% {
	  	height: 0;
	  	width: 0;
	  	opacity: 1;
	}
	20% {
		height: 0px;
	  	width: 10px;
	  	opacity: 1;
	}
	40% {
		height: 20px;
		width: 10px;
		opacity: 1;
	}
	100% {
	  	height: 20px;
	  	width: 10px;
	  	opacity: 1;
	}
  }