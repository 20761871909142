.navContainer {
	position: relative;
	display: flex;
	align-items: center;
}

.navItemContainer {
	height: 6.25rem;
	display: inline-flex;
	justify-content: left;
	align-items: center;
	padding-left: 2rem;
	font-size: 24px;
	margin-right: auto;
}

.navLink {
	position: relative;
	margin-right: 2rem;
	text-decoration: none;
	color: #384457;
	opacity: 0.5;
}

.selectedLink {
	position: relative;
	margin-right: 2rem;
	color: #384457;
	padding: 0px 0px 10px 0px;
	margin-top: 11px;
	text-decoration: none;
	opacity: 1.0;
	border-bottom: 2px solid #384457;
}

.datePicker {
	margin-right: 30px;
	text-align: center;
	color: #384457;
	cursor: pointer;
}

.chevron {
	height: 5px;
	width: 10px;
	vertical-align: middle;
	margin-left: 10px;
}

.popContainer {
	background-color: rgb(224, 225, 227);
	border-radius: 5px;
}

.dateSelectionTable {
	padding: 20px, 0px;
	border-collapse: collapse;
	border-radius: 5px;
}

.dateSelectionTable td {
	padding: 10px;
	cursor: pointer;
}

.dateSelectionTable tr:first-child td:first-child {
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.dateSelectionTable tr:last-child td:last-child {
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.dateSelectionTable tr:hover {
	background-color: rgba(56, 68, 87, .4);
}

.releaseContainer {
	position: absolute;
	right: 30px;
	top: 10px;
}

.badge {
	position: absolute;
	top: -11px;
	min-width: 22px;
	height: 22px;
	line-height: 22px;
	right: -15px;
	padding: 0px 8px;
	border-radius: 50%;
	background-color: red;
	color: white;
	font-size: 12px;
	text-align: center;
}