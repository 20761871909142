.container {
	height: 400px;
	background-color: rgba(244, 244, 244, 0.95);
	box-shadow: 2px 2px 3px 0px rgba(79, 79, 79, 0.2);
	overflow: hidden;
	font-size: 20px;
	font-weight: bold;
	color: #384457;
	padding: 25px;
	margin-top: 25px;
	padding-top: 60px;
}

.updatePayment {
	font-size: 16px;
	font-weight: 700;
	font-family: inherit;
	border: none;
	background-color: #384457;
	border-radius: 5px;
	margin-right: 30px;
	padding: 15px 10px;
	width: 210px;
	color: white;
	margin-top: 60px;
}