html {
	height: 100%;
}

body {
	height: 100%;
	width: 100%;
}

#root {
	display: inline-block;
	height: 100%;
	min-width: 100%;
}

.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.content-container {
	padding-left: 2rem;
	padding-right: 2rem;
	padding-bottom: 2rem;
	min-width: 100%;
	min-height: 100%;
}

* {
	box-sizing: border-box;
}

.formTextField {
	margin-left: 10px;
	margin-right: 30px;
}

.leaflet-container {
	width: 100%;
	height: 100%;
}
