
/*
----------
dateFilter
----------
*/

.dateFilterContainer {
	margin-right: 30px;
	text-align: center;
	color: #384457;
	cursor: pointer;
	font-size: 24px;
}

.chevron {
	height: 5px;
	width: 10px;
	vertical-align: middle;
	margin-left: 10px;
}

.popContainer {
	background-color: rgb(224, 225, 227);
	border-radius: 5px;
	min-width: 150px;
}

.dateSelectionTable {
	padding: 20px, 0px;
	border-collapse: collapse;
	border-radius: 5px;
	width: 100%;
	line-height: 1.2;
}

.dateSelectionTable td {
	padding: 10px;
	cursor: pointer;
}

.dateSelectionTable tr:first-child td:first-child {
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.dateSelectionTable tr:last-child td:last-child {
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.dateSelectionTable tr:hover {
	background-color: rgba(56, 68, 87, .4);
}

.checkmark {
	height: 11px;
	width: 13px;
	margin-top: 3px;
	float: right;
}

.datesContainer {
	width: 286px;
	z-index: 3000;
}

/*
----------
barGraph
----------
*/

.hint {
	font-size: 14px;
}

.hintBody {
	background-color: white;
	border-radius: 5px;
	color: #384457;
	line-height: 1rem;
	padding: 1px 0.5rem;
	margin: 0px;
}

.hintBody p {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

.graphContainer {
	position: relative;
	height: 100%;
	width: 100%;
}

.selectedGraph {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
}



/*
----------
Search Bar
----------
*/

.searchContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0px 5px;
	justify-content: start;
}

.searchTitle {
	font-size: 27px;
	font-weight: bold;
	margin-bottom: 20px;
}

.searchSubTitle {
	font-size: 16px;
	margin-bottom: 30px;
	white-space: pre-line;
}

.searchField {
	height: 32px;
	width: 400px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;
	cursor: text;
	float: left;
}

.searchField:focus {
	outline: none;
}

.clearButton {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	border: none;
	text-decoration: none;
	height: 32px;
	width: 40px;
	text-align: center;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	color: #ffffff;
	background-color: #2979ff;
	cursor: pointer;
	float: left;
}

.searchBar {
	display: block;
	width: 100%;
	margin-bottom: 10px;
}

.addButton {
	width: fit-content;
	display: inline-block;
	background: #7593C3;
	border: 0px;
	color: #FFFFFF;
	border-radius: 5px;
	padding: 0.5em 2em;
	font-size: 16px;
	cursor: pointer;
	font-weight: bold;
	margin: 0px 0px 30px 0px;
}

.modalContainer {
	position: absolute;
	border-radius: 5px;
	width: 600px;
	max-height: 800px;
	padding: 20px 20px 40px 20px;
	background-color: #e9e9e9;
	outline: none;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	color: #384457;
	font-weight: bold;
	box-shadow: 2px 2px 3px 0px rgba(79, 79, 79, 0.2);
}

.modalContainer select {
	outline:none;
	margin-left: 10px;
	font-size: 16px;
}

.modalTextField {
	width: 200px;
	border-radius: 5px;
	border: 1px solid #e5e5e5;
	padding: 0.4rem 0.5rem;
	margin: 10px;
	outline: none;
	font-size: 14px;
}

.modalButton {
	background: #7593C3;
	border: 0px;
	color: #FFFFFF;
	border-radius: 5px;
	padding: 0.4em 2em;
	font-size: 16px;
	margin: 0px;
	margin-top: 50px;
	cursor: pointer;
}

.modalCancelButton {
	background: #FFFFFF;
	border: 2px solid #7593C3;
	color: #7593C3;
	border-radius: 5px;
	padding: 0.32em 2em;
	font-size: 16px;
	margin: 0px;
	cursor: pointer;
	margin-left: 30px;
}

.modalDeleteButton {
	background: #A91B1A;
	border: 0px;
	color: #FFFFFF;
	border-radius: 5px;
	padding: 0.4em 1.5em;
	font-size: 16px;
	margin: 0px;
	cursor: pointer;
}

.modalTitle {
	font-size: 26px;
	color: #384457;
	font-weight: bold;
}

.barGraphContainer {
	padding-bottom: 60px;
}

/*
----------
Report Button
----------
*/

.createReportButtonActive {
	display: block;
	width: fit-content;
	font-size: 16px;
	font-weight: bold;
	color: white;
	background-color: #7593C3;
	border-radius: 5px;
	border-width: 0px;
	padding: 0.5em 2em;
	cursor: pointer;
	margin-top: 30px;
}

.createReportButtonDisabled {
	display: block;
	font-size: 16px;
	font-weight: bold;
	color: white;
	background-color: #696969;
	border-radius: 5px;
	border-width: 0px;
	padding: 0.5em 2em;
	cursor: pointer;
	margin-top: 30px;
}

/*
----------
Terms Form
----------
*/

.termsBackground {
	position: relative;
	background-color: #28303e;
	width: 100%;
	padding-top: 40px;
	padding-bottom: 40px;
	height: 100%;
	overflow-y: scroll;
}

.termsContainer {
	width: 600px;
	margin-left: auto;
	margin-right: auto;
	background-color: white;
	border-radius: 5px;
	overflow: hidden;
	box-shadow: 8px 8px 12px #14181f;
	padding: 20px 40px;
}

.termsHeader {
	font-size: 26px;
	font-weight: bold;
	color: #384457;
	margin-bottom: 10px;
}

.termsTitle {
	font-size: 18px;
	font-weight: bold;
	color: #384457;
}

.termsAccept{
	display: block;
	font-size: 16px;
	font-weight: bold;
	color: white;
	background-color: #7593C3;
	border-radius: 5px;
	margin-left: auto;
	margin-right: auto;
	border-width: 0px;
	padding: 0.8em 1em;
	cursor: pointer;
	margin-top: 40px;
}

.choroplethContainer {
	padding: 0px;
	width: 100%;
	height: 100%;
	max-height: 100%;
}

/*
-----------
TIER BUTTON
-----------
*/
.tierButton {
	width: 210px;
	color: white;
	background-color: #7593C3;
	border-radius: 5px;
	text-decoration: none;
	text-align: center;
	min-width: 210px;
	font-size: 18px;
	font-weight: 600;
	padding: 15px 10px;
	border: none;
	cursor: pointer;
}

.tierButtonDisabled {
	width: 210px;
	color: white;
	background-color: #696969;
	border-radius: 5px;
	text-decoration: none;
	text-align: center;
	min-width: 210px;
	font-size: 18px;
	font-weight: 600;
	padding: 15px 10px;
	border: none;
	cursor: pointer;
}

/*
----------
Loading
----------
*/

.loader {
	display: flex;
	flex-direction: column;
	align-items: center;
}

#loaderAnimation {
	position: relative;
	width: 5.4em;
}

#loadingText {
	margin-top: -5px;
	text-align: center;
	color: #384457;
	font-weight: bold;
}
  
#hill {
	position: relative;
	width: 7.1em;
	height: 6.4em;
	top: 2.2em;
	left: 1.6em;
	background-color: transparent;
	border-left: .25em solid #384457;
	transform: rotate(45deg);
}
    
#box {
	position: relative;
	left: 0;
	bottom: -5.8em;
	width: 1em;
	height: 1em;
	background-color: transparent;
	border: .25em solid #384457;
	border-radius: 15%;
	transform: translate(0, -1em) rotate(-45deg);
	animation: push 2.5s cubic-bezier(.79, 0, .47, .97) infinite;
}
  
  @keyframes push {
	0% {
	  transform: translate(0, -1em) rotate(-45deg);
	}
	5% {
	  transform: translate(0, -1em) rotate(-50deg);
	}
	20% {
	  transform: translate(1em, -2em) rotate(47deg);
	}
	25% {
	  transform: translate(1em, -2em) rotate(45deg);
	}
	30% {
	  transform: translate(1em, -2em) rotate(40deg);
	}
	45% {
	  transform: translate(2em, -3em) rotate(137deg);
	}
	50% {
	  transform: translate(2em, -3em) rotate(135deg);
	}
	55% {
	  transform: translate(2em, -3em) rotate(130deg);
	}
	70% {
	  transform: translate(3em, -4em) rotate(217deg);
	}
	75% {
	  transform: translate(3em, -4em) rotate(220deg);
	}
	100% {
	  transform: translate(0, -1em) rotate(-225deg);
	}
}

.mapContainer {
	height: 100%;
}