body.public {
	background-color: $westock-cream;

	div#root {
		// Temporary Hack w/ App-level Styling
		display: inherit;
		height: inherit;
		min-width: inherit;
	}
}


.bg-gray {
	background-color: gray;
}