.header {
	display: flex;
	min-height: 8rem;
	height: 8rem;
	background-image: linear-gradient(#CE2E33, #A91817);
	align-items: stretch;
	flex-wrap: nowrap;
}

.titleContainer {
	display: flex;
	align-items: center;
	padding: 2rem;
	font-size: 2.5rem;
	text-align: left;
	vertical-align: middle;
	flex-grow: 1;
	color: white;
	font-weight: bold;
}

.title{
	cursor: pointer;
}

.userContainer {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	text-align: right;
	padding: 2rem;
	flex-grow: 1;
}

.user {
	color: white;
	margin-right: 1rem;
}

.logo {
	height: 75px;
	width: 75px;
	background-color: rgb(255, 255, 255);
	border-radius: 50%;
	object-fit: contain;
}

.logout {
	font-size: 12px;
	margin-top: 4px;
	cursor: pointer;
}

.chevron {
	height: 10px;
	width: 20px;
	vertical-align: middle;
	margin-left: 10px;
}

.popContainer {
	position: relative;
	background-color: rgb(224, 225, 227);
	border-radius: 5px;
	min-width: 350px;
	min-height: 38px;
	max-height: 400px;
	overflow-y: scroll;
	z-index: 10;
}

.dateSelectionTable {
	padding: 20px, 0px;
	border-collapse: collapse;
	border-radius: 5px;
	width: 100%;
	overflow-y: scroll;
	line-height: 1.2;
}

.dateSelectionTable td {
	padding: 10px;
	cursor: pointer;
}

.dateSelectionTable tr:first-child td:first-child {
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.dateSelectionTable tr:last-child td:last-child {
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.dateSelectionTable tr:hover {
	background-color: rgba(56, 68, 87, .4);
}

.checkmark {
	height: 11px;
	width: 13px;
	margin-top: 3px;
	float: right;
}

.datesContainer {
	width: 286px;
}

.filterText {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0px;
	left: 0px;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 32px;
	font-weight: bold;
	color: #384457;
	text-align: center;
	opacity: 0;
	animation: fadeOut 2s;
	pointer-events: none;
}

@keyframes fadeOut {
	0% {opacity:1;}
	100% {opacity:0;}
}
