.report {
	position: relative;
	color: #384457;
	font-size: 16px;
}

.title {
	color: #384457;
	font-size: 22px;
	font-weight: bold;
	margin-bottom: -10px;
}

.linkBoxesContainer {
	display: inline-block;
}

.navContainer {
	margin-bottom: 60px;
}

.section {
	margin-bottom: 40px;
	white-space: pre-wrap;
}

.sectionTitle{
	color: #384457;
	font-size: 26px;
	font-weight: bold;
	margin-bottom: 15px;
}

.navLink {
	margin-bottom: 5px;
}