.marketplaceContainer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 20px;
	row-gap: 40px;
}

.marketplaceSection {
	max-width: 400px;
	text-align: center;
}

.specialOffer {
	color: #384457;
	font-weight: bold;
	font-size: 16px;
}

.title {
	color: #384457;
	font-size: 40px;
	font-weight: bold;
}

.marketplaceLogo {
	width: 200px;
	max-height: 200px;
	object-fit: contain;
}

.description {
	font-size: 16px;
}

.applyButton {
	display: inline-block;
	text-decoration: none;
	color: white;
	background-color: #384457;
	border-radius: 5px;
	padding: 10px 20px;
	font-weight: bold;
	margin-top: 20px;
}