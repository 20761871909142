.tile {
	display: flex;
	position: relative;
	background-color: #F4F4F4;
	height: 18.75rem;
	box-shadow: 2px 2px 3px 0px rgba(79, 79, 79, 0.2);
	border-radius: 5px;
	font-size: 50px;
	color: #384457;
	text-align: center;
	font-weight: bold;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
}

.tile p {
	font-size: 20px;
	font-weight: normal;
}

.tile.list {
	line-height: 2000.0px;
}

.tile:hover {
	background-color: rgb(238, 238, 238);
}

.graphContainer {
	height: 15rem;
	width: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
}

.graphTitleContainer {
	text-align: left;
	position: relative;
	padding-left: 25px;
	padding-top: 10px;
	height: 60px;
	margin-bottom: 0px;
}

.graphTitle {
	vertical-align: top;
	display: inline;
	background-color: #384457;
	color: white;
	border-radius: 5px;
	padding: 5px 10px;
}

.hintContainer {
	background-color: #d6d6d6;
	border-radius: 5px;
	color: #384457;
	line-height: 1rem;
	padding: 1px 0.5rem;
	margin: 0px;
}

.hintContainer p {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

/* TOP PRODUCTS TILE */

.topProductsContainer {
	position: relative;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 100%;
	height: 100%;
	gap: 10px;
	padding: 25px;
}

.topProductContainer1 {
	position: relative;
	display: flex;
	flex-direction: column;
	background-color: #E0E1E3;
	border-radius: 5px;
	padding: 10px;
}

.topProductContainer2 {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 1fr 1fr;
	gap: 10px;
	overflow: hidden;
}

.topProductContainer3 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 100%;
	position: relative;
	background-color: #E0E1E3;
	gap: 10px;
	border-radius: 5px;
	padding: 10px;
	height: 50px;
	min-height: 100%;
}

.topProductVerticalCenter {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.topProductImageContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	border-radius: 5px;
}

.topProductImage {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.topProductTitleContainer {

}

/* STORE TILE */

.topStoresTile {
	height: 100%;
	padding: 30px 25px 25px 25px;
	display: grid;
	grid-template-rows: auto min-content;
	gap: 30px;
}

.topStoresContainer {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 10px;
	max-width: 100%;
	text-overflow: ellipsis;
}

.topStoresChild {
	background-color: #E0E1E3;
	border-radius: 5px;
	font-size: 20px;
	position: relative;

}

.topStoreTitle {
	display: table-cell;
	text-align: left;
	background-color: #384457;
	color: white;
	border-radius: 5px;
	font-size: 20px;
	font-weight: 600;
	padding: 5px 12px;

}

.verticalCenter {
	margin: 0;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 100%;
}

.storeCount {
	font-size: 30px;
	font-weight: bold;
	text-align: center;
}

.storeName {
	font-size: 16px;
	font-weight: 600;
	text-align: center;
}

.storeCount1 {
	font-size: 30px;
	font-weight: bold;
	text-align: center;
}

.storeName1 {
	font-size: 16px;
	font-weight: 600;
	text-align: center;
}


/* TOP CATEGORIES */

.topCategoriesTile {
	height: 100%;
	padding: 35px 25px 25px 25px;
	display: grid;
	grid-template-rows: auto min-content;
	gap: 30px;
}

.topCategoriesContainer {
	display: grid;
	grid-template-columns: auto auto;
	gap: 10px;
	text-align: left;
	margin-bottom: 0px;
}

.topCategory {
	background-color: #E0E1E3;
	border-radius: 5px;
	font-size: 20px;
	font-weight: 600;
	color: #384457;
	padding: 10px 10px 10px 25px;
}

.topCategory1 {
	background-color: #E0E1E3;
	border-radius: 5px;
	font-size: 20px;
	font-weight: 600;
	color: #384457;
	grid-column-start: 1;
	grid-column-end: 3;
	padding: 10px 10px 10px 25px;
}